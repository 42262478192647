/* eslint-disable react/jsx-fragments */
import React, { Fragment } from "react";

import Card from "../../../components/Card";
import CardSection from "../../../components/CardSection";
import Column from "../../../components/Column";
import Columns from "../../../components/Columns";
import Fieldset from "../../../components/Fieldset";
import InputSelect from "../../../components/InputSelect";

const getHelpText = Text => {
  if (process.env.COUNTRY_CODE === "AU") {
    return (
      <Fragment>
        <Text>
          This insurance does not cover travel within Australia. Travel to the
          USA, Caribbean Islands, Canada or Mexico is only covered for fertility
          procedures.
        </Text>
        <Text>
          You are also not covered if you travel to or through any of the
          following countries: Afghanistan, Burkina Faso, Central African
          Republic, Chad, Chechnya, Cuba, Democratic Republic of the Congo,
          Eritrea, Ethiopia, Iran, Iraq, Israel (Gaza only), Libya, Mali,
          Mauritania, Nigeria, North Korea, Somalia, Sudan, South Sudan, Syria
          and Yemen.
        </Text>
        <Text>
          If your destination is not available from the list please contact us
          on +61 (0) 3 9258 1777.
        </Text>
      </Fragment>
    );
  }
  if (process.env.COUNTRY_CODE === "IE") {
    return (
      <Fragment>
        <Text>
          This insurance does not cover travel within the Republic of Ireland. Travel to the USA, Caribbean Islands, Canada or Mexico is only covered for fertility procedures.
        </Text>
        <Text>
          You are also not covered if you travel to or through any of the following countries: Afghanistan, Burkina Faso, Central African Republic, Chad, Chechnya, Cuba, Democratic Republic of the Congo, Eritrea, Ethiopia, Iran, Iraq, Israel (Gaza only), Libya, Mali, Mauritania, Nigeria, North Korea, Russia, Somalia, Sudan, South Sudan, Syria, Ukraine and Yemen.
        </Text>
        <Text>
          If your destination is not available from the list please contact us on +44 (0)203 409 1236.
        </Text>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Text>
        This insurance does not cover travel within the UK. Travel to the USA,
        Caribbean Islands or Canada is only covered for fertility
        procedures.
      </Text>
      <Text>
        You are also not covered if you travel to or through any of the following countries: Afghanistan, Burkina Faso, Central African Republic, Chad, Chechnya, Cuba, Democratic Republic of the Congo, Eritrea, Ethiopia, Iran, Iraq, Israel (Gaza only), Libya, Mali, Mauritania, Nigeria, North Korea, Russia, Somalia, Sudan, South Sudan, Syria, Ukraine and Yemen.
      </Text>
      <Text>
        If your destination is not available from the list please contact us on
        0203 409 1236.
      </Text>
    </Fragment>
  );
};

export default ({
  countriesData,
  formErrors,
  isDisabled,
  quoteData,
  selectedCategoryId,
}) => {
  const defaultCountry = countriesData.find(item => item.name === quoteData.country);

  return (
    <Fieldset disabled={isDisabled} name="country_id">
      <Card
        key={selectedCategoryId}
        headerText="Where will you be travelling to?"
        renderHelpText={getHelpText}
      >
        <CardSection>
          <Columns>
            <Column>
              <InputSelect
                isSearchable
                iconName="triangle"
                name="country_id"
                data={countriesData}
                placeholder="Select"
                defaultValue={defaultCountry ? { value: defaultCountry?.id, label: defaultCountry?.name } : {}}
                errors={formErrors.country_id}
              />
            </Column>
          </Columns>
        </CardSection>
      </Card>
    </Fieldset>
  );
};
